export default {
    message: {
        protocolTypeList1: "WiFi-蓝牙",
        protocolTypeList2: "蓝牙",
        protocolTypeList3: "LTE Cat.1-蓝牙",
        protocolTypeList4: "LTE Cat.M-蓝牙",
        productStatusList1: "开发中",
        productStatusList2: "申请发布",
        productStatusList3: "已发布",
        certifiedEnterpriseStatusList1: "认证中",
        certifiedEnterpriseStatusList2: "认证成功",
        certifiedEnterpriseStatusList3: "认证失败",
        upgradeTypeList1: "全部",
        upgradeTypeList2: "固件",
        upgradeStateList1: "升级成功",
        upgradeStateList2: "升级失败",
        regionList2: "广东",
        regionList3: "长沙",
        regionList4: "南昌",
        releaseModeList1: "未发布",
        releaseModeList2: "灰度发布",
        releaseModeList3: "全量发布",
        releaseModeList4: "指定发布",
        formatList1: "数值型",
        formatList2: "整数型",
        formatList3: "小数型",
        formatList4: "枚举型",
        formatList5: "布尔型",
        formatList6: "字符型",
        formatList7: "透传型",
        formatList8: "故障型",
        formatList9: "短整型",
        formatList10: "长整型",
        authorityList1: "只下发(wo)",
        authorityList2: "只上报(ro)",
        authorityList3: "可下发可上报(rw)",
        effectTypeList1: "标准功能",
        effectTypeList2: "自定义功能",
        empowerStatusList1: "追加中",
        empowerStatusList2: "导入中",
        empowerStatusList3: "待审核",
        empowerStatusList4: "申请成功",
        empowerStatusList5: "申请失败",
        deviceStateList1: "待激活",
        deviceStateList2: "已激活",
        deviceTypeList1: "正常",
        deviceTypeList2: "虚拟",
        deviceTypeList3: "预览",
        deviceNetworkStateList2: "在线",
        deviceNetworkStateList3: "离线",
        workOrderTypeList1: "维修工单",
        workOrderTypeList2: "客服咨询",
        workOrderStatesList1: "待处理",
        workOrderStatesList2: "处理中",
        workOrderStatesList3: "已处理",
        deliveryTypeList1: "下发",
        deliveryTypeList2: "上报",
        logTypeList1: "通讯日志",
        logTypeList2: "上下线日志",
        logTypeList3: "绑定/解绑日志",
        logTypeList4: "配网日志",
        empowerObjectList1: "产品",
        empowerObjectList2: "服务",
        empowerObjectiveList1: "调试",
        empowerObjectiveList2: "量产",
        empowerObjectiveList3: "授权",
        autoAuthList1: "是",
        autoAuthList2: "否",
        antennaTypeList1: "外置天线",
        antennaTypeList2: "板载天线",
        antennaTypeList3: "未知",
        activeTypeList1: "唯一标识激活",
        activeTypeList2: "License激活",
        activeTypeList3: "自动激活"
    },
    index: {
        text: "APP下载",
        text1: "开放与共创",
        text2: "小蜂连连（或称Langolink）致力于提供端到端的智能、联网产品解决方案，具体包括云平台、智能模块、AI算法模型、移动APP。即可实现开箱即用；也提供各端的应用开发基础API，方便客户自行开发必要的个性化功能。",
        text3: "云平台",
        text4: "中台、业务平台分离设计，扩展性好",
        text5: "智能模组接入、控制、集中控制、固件空中升级、算法模型远程更新，为产品提供全方位的服务",
        text6: "移动APP",
        text7: "插件化开发，扩展性好",
        text8: "安卓、IOS、微信小程序多端覆盖",
        text9: "各类智能硬件模组",
        text10: "智能模组原生接入云平台，免接入开发，用户只需聚焦个性化的业务开发;智能模组功能覆盖全面，按需选择",
        text11: "强AI计算SOC模块",
        text12: "支持0.2~6T的NPU，1多64路摄像头输入，8K编解码；",
        text13: "支持小尺寸液晶屏显示，以及HDMI图像输出；",
        text14: "支持ubuntu、AOSP、OpenHarmony等多种OS",
        text15: "强业务计算SOC模块",
        text16: "支持双核~8核，主频高达1.8Hz的ARM或MIPS CPU",
        text17: "支持有线、无线联网，可驱动大尺寸液晶显示屏",
        text18: "基础连接WIFI SOC模块",
        text19: "支持相关网络技术:",
        text20: "WIFI4 + WIFI5 + 蓝牙",
        text21: "AI算法模型",
        text22: "软硬结合、多模态数据融合，更快、更准、更省",
        text23: "图像",
        text24: "支持对象检测、识别、跟踪等",
        text25: "声音",
        text26: "支持回声消除、声源定位、噪声抑制等",
        text27: "运动控制",
        text28: "快速建图、路径规划、主动避障等",
        text29: "合作流程",
        text30: "成为开发者",
        text31: "注册平台账号、获得开发权限",
        text32: "创建产品",
        text33: "明确产品功能及技术方案",
        text34: "研发产品",
        text35: "开发软硬件功能并测试通过",
        text36: "发布产品",
        text37: "取得平台权限即可推广销售",
        text38: "扫描二维码下载APP",
        text39: "文档中心"
    },
    login: {
        text1: "请选择数据中心",
        text2: "请选择语言",
        text3: "登录",
        text4: "注册",
        text5: "请选择国家/地区",
        text6: "手机号",
        text7: "邮箱",
        text8: "请输入",
        text9: "验证码",
        text10: "密码",
        text11: "忘记密码？",
        text12: "密码登录",
        text13: "已阅读并同意",
        text14: "《用户协议》",
        text15: "《隐私政策》",
        text16: "和",
        text17: "请输入邮箱或手机号",
        text18: "请输入有效的邮箱或手机号",
        text19: "请输入手机号",
        text20: "请输入有效的手机号",
        text21: "请输入邮箱",
        text22: "请输入有效的邮箱",
        text23: "获取验证码",
        text24: "请输入密码",
        text25: "请输入验证码",
        text26: "请先勾选阅读协议",
        text27: "后重新获取",
        text28: "重新获取验证码",
        text29: "确认密码",
        text30: "请再次输入密码",
        text31: "两次输入密码不一致!",
        text32: "必须包含大小写字母、数字、特殊字符、长度不小于8",
        text33: "注册成功！",
        text34: "验证码登录",

        text35: "重置密码",
        text36: "重置成功"
    },
    main: {
        text1: "个人中心",
        text2: "退出登录",
        text3: "文档",
        text4: "控制台",
        text5: "切换成功！",
        text6: "是否退出登录？",
        text7: "提示",
        text8: "退出",
        text9: "取消",
        text10: "请选择语言",
        text11: "个人空间"
    },
    mainIndex: {
        text1: "产品开发",
        text2: "产品名称/PID",
        text3: "全部品类",
        text4: "全部通讯方式",
        text5: "全部状态",
        text6: "没有产品拟物图",
        text7: "编辑",
        text8: "复制",
        text9: "删除",
        text10: "设备管理",
        text11: "OTA升级",
        text12: "是否删除该产品？",
        text13: "提示",
        text14: "删除",
        text15: "取消",
        text16: "删除成功",
        text17: "复制产品",
        text18: "产品名称：",
        text19: "请输入产品名称",
        text20: "产品型号：",
        text21: "请输入产品型号，仅支持小写字母或数字",
        text22: "取 消",
        text23: "确 认",
        text24: "输入不能超过20个字符",
        text25: "只能输入小写字母、下划线及数字",
        text26: "复制成功"

    },
    addProduct: {
        text1: "创建产品",
        text2: "品类名称",
        text3: "没有搜索到相关品类",
        text4: "产品名称：",
        text5: "20个字符内",
        text6: "产品型号：",
        text7: "请输入产品型号，仅支持大小写字母、下划线及数字",
        text8: "通讯方式：",
        text9: "选择通讯方式",
        text10: "配网方式：",
        text11: "产品拟物图：",
        text12: "168*168像素,PNG格式,透明底,100KB以内",
        text13: "备注：",
        text14: "请输入备注",
        text15: "新建产品",
        text16: "选择品类",
        text17: "选择开发方案",
        text18: "填写基础信息",
        text19: "创建",
        text20: "非嵌入式系统接入",
        text21: "适用如Android、Linux系统等设备接入",
        text22: "嵌入式系统接入",
        text23: "适用如RTOS系统等嵌入式的设备接入",
        text24: "云对云接入",
        text25: "适用已有云平台的设备接入",
        text26: "请输入产品名称",
        text27: "输入不能超过20个字符",
        text28: "请选择产品类别",
        text29: "请选择通讯方式",
        text30: "只能输入大小写字母、下划线及数字",
        text31: "请选择配网方式",
        text32: "产品拟物图仅支持PNG格式！",
        text33: "上传图片大小不能超过 100KB！",
        text34: "上传图片尺寸不符合,只能是168*168!",
        text35: "请选择品类",
        text36: "请选择开发方案",
        text37: "添加成功"

    },
    deviceManagement: {
        text1: "设备管理",
        text2: "设备概览",
        text3: "总激活设备数(台)",
        text4: "在线设备数",
        text5: "离线设备数",
        text6: "设备列表",
        text7: "请输入",
        text8: "手机号码",
        text9: "设备ID",
        text10: "请选择",
        text11: "至",
        text12: "开始日期",
        text13: "结束日期",
        text14: "类型",
        text15: "状态",
        text16: "在线",
        text17: "离线",
        text18: "绑定用户",
        text19: "激活日期",
        text20: "激活状态",
        text21: "在线状态",
        text22: "操作",
        text23: "日志"
    },
    product: {
        text1: "产品编辑",
        text2: "没有产品拟物图",
        text3: "产品型号：",
        text4: "品类：",
        text5: "通讯方式：",
        text6: "备注：",
        text7: "查看详情",
        text8: "功能定义",
        text9: "设备接入",
        text10: "应用开发",
        text11: "高级配置",
        text12: "产品发布",
        text13: "复制成功",
        text14: "基础配置",
        text15: "产品名称：",
        text16: "请输入产品名称",
        text17: "只能输入小写字母、下划线及数字",
        text18: "请选择通讯方式",
        text19: "配网方式：",
        text20: "产品拟物图：",
        text21: "168*168像素,PNG格式,透明底,100KB以内",
        text22: "保 存",
        text23: "输入不能超过20个字符",
        text24: "只能输入大小写字母、下划线及数字",
        text25: "修改成功",
        text26: "产品拟物图仅支持PNG格式！",
        text27: "上传图片大小不能超过 100KB！",
        text28: "上传图片尺寸不符合,只能是168*168!",
        text29: "产品信息",
        text30: "产品状态：",
        text31: "产品类别：",
        text32: "标准功能",
        text33: "导出所有功能",
        text34: "功能名称",
        text35: "功能标识",
        text36: "属性名称",
        text37: "属性标识",
        text38: "数据类型",
        text39: "属性备注",
        text40: "权限",
        text41: "功能备注",
        text42: "属性操作",
        text43: "编辑",
        text44: "详情",
        text45: "删除",
        text46: "自定义功能",
        text47: "添加属性",
        text48: "功能配置",
        text49: "根据您的需求配置相关的服务，若不需要请点击关闭",
        text50: "暂无功能配置项",
        text51: "设置成功",
        text52: "（必选）",
        text53: "是否删除该属性？",
        text54: "提示",
        text55: "取消",
        text56: "删除成功",
        text57: "是否删除该功能？",
        text58: "添加功能",
        text59: "请输入功能名称",
        text60: "请输入功能标识",
        text61: "请输入备注",
        text62: "功能属性",
        text63: "取 消",
        text64: "提交中",
        text65: "确 定",
        text66: "输入不能超过25个字符",
        text67: "请选择权限",
        text68: "输入不能超过32个字符",
        text69: "只能输入字母、下划线及数字",
        text70: "添加成功",
        text71: "请输入属性名称",
        text72: "请输入属性标识",
        text73: "格式",
        text74: "类型长度",
        text75: "请选择类型长度",
        text76: "数值范围",
        text77: "倍数",
        text78: "数据将除以10的指数，适用于小数，如上报值为1，小数因子设置为1，则转化后数据为0.1",
        text79: "请选择倍数",
        text80: "步长",
        text81: "请输入属性步长",
        text82: "单位",
        text83: "请输入属性单位",
        text84: "枚举值：",
        text85: "故障值：",
        text86: "枚举值key",
        text87: "故障值key",
        text88: "枚举值内容",
        text89: "故障值内容",
        text90: "最大值：",
        text91: "最长字节为",
        text92: "请输入属性备注",
        text93: "请选择格式",
        text94: "请输入步长",
        text95: "请输入单位",
        text96: "请填写完列表",
        text97: "步长不能为0",
        text98: "开始值不能大于等于结束值",
        text99: "请填写数值范围",
        text100: "请输入最大字符串",
        text101: "新增属性",
        text102: "修改属性",
        text103: "属性详情",
        text104: "名称",
        text105: "确 认",
        text106: "操作成功",
        text107: "修改功能",
        text108: "功能详情",
        text109: "选择标准功能",
        text110: "标识符",
        text111: "你已经选择全部可选功能",
        text112: "已选标准功能",
        text113: "本次未选择任何功能",
        text114: "请选择需要添加的标准功能！",
        text115: "下载",
        text116: "前往授权",
        text117: "前往调试",
        text118: "固件版本列表",
        text119: "新建版本",
        text120: "固件版本",
        text121: "更新日志",
        text122: "上传时间",
        text123: "状态",
        text124: "未发布",
        text125: "已发布",
        text126: "操作",
        text127: "发布",
        text128: "请选择合适的接入协议",
        text129: "烧录鉴权凭证",
        text130: "设备调试（调试前请确认是否已开发完成）",
        text131: "上传含SDK固件",
        text132: "是否发布当前固件？",
        text133: "确认",
        text134: "发布成功",
        text135: "是否删除当前固件？",
        text136: "上传SDK固件包",
        text137: "上传MCU固件",
        text138: "新增固件",
        text139: "格式仅支持字母及数字及小数点",
        text140: "固件包",
        text141: "点击上传",
        text142: "请输入更新日志",
        text143: "请输入固件版本号",
        text144: "只能输入字母及数字及小数点",
        text145: "请输入更新日志",
        text146: "确定移除", // Confirm to remove the
        text147: "请上传固件",
        text148: "上传成功",
        text149: "选择模组",
        text150: "没有模组拟物图",
        text151: "型号",
        text152: "芯片",
        text153: "天线",
        text154: "重新选择",
        text155: "串口通讯协议",
        text156: "MCU固件版本列表",
        text157: "请选择接入云端模组",
        text158: "请下载开发资料",
        text159: "设备调试（调试前请确认是否已开发完成）",
        text160: "上传固件",
        text161: "模组列表",
        text162: "请选择接入方式",
        text163: "线下自研",
        text164: "线上生成",
        text165: "应用SDK",
        text166: "版本列表",
        text167: "版本号",
        text168: "预览",
        text169: "请下载应用开发资料",
        text170: "应用开发调试",
        text171: "上传RN应用包（上传前请确认调试完成）",
        text172: "是否发布当前应用？",
        text173: "是否删除当前应用？",
        text174: "上传RN应用包",
        text175: "RN应用包",
        text176: "Android包：",
        text177: "IOS包：",
        text178: "HD包：",
        text179: "输入版本号与上传RN应用包版本号不一致",
        text180: "请输入RN应用版本号",
        text181: "RN包名不正确",
        text182: "当前上传的RN包不属于", //The currently uploaded RN package does not belong to the
        text183: "平台", //platform
        text184: "当前上传的android包与ios包版本号不一致",
        text185: "当前上传的ios包与android包版本号不一致",
        text186: "当前上传的", //The PID of the currently uploaded
        text187: "包的PID不属于本产品", //package does not belong to this product
        text188: "请上传ios应用",
        text189: "请上传android应用",
        text190: "请上传HD应用",
        text191: "校验错误，请检查上传RN包版本号与PID是否正确",
        text192: "设置",
        text193: "配网引导",
        text194: "配置设备联网说明、配网初始化引导的相关示意图",
        text195: "步骤",
        text196: "添加步骤",
        text197: "引导标题",
        text198: "请输入引导标题",
        text199: "引导说明",
        text200: "引导图片",
        text201: "格式为png、jpeg、jpg、gif、bmp",
        text202: "暂无图片预览",
        text203: "下一步",
        text204: "请输入引导说明",
        text205: "输入不能超过40个字符",
        text206: "，请上传引导图！", // , please upload the guide map!
        text207: "提交成功！",
        text208: "图片仅支持jpg、jpeg、png、gif、bmp格式！",
        text209: "图片大小不能超过 10MB！",
        text210: "上传图片尺寸不符合,只能是320*320！",
        text211: "产品品类：",
        text212: "请输入型号",
        text213: "请选择固件",
        text214: "请选择面板",
        text215: "确认上线",
        text216: "请确认基础信息",
        text217: "请确认固件版本",
        text218: "请确认RN应用版本",
        text219: "请输入产品型号",
        text220: "上传图片尺寸不符合,只能是168*168！",
        text221: "请上传产品拟物图",
        text222: "功能操作",

        text223: "功能配置",
        text224: "密码卡配置",
        text225: "卡片路由地址:",
        text226: "请输入卡片路由地址",
        text227: "卡片背景类型:",
        text228: "请选择卡片背景类型",
        text229: "背景图片",
        text230: "背景颜色",
        text231: "卡片背景图片:",
        text232: "卡片背景颜色:",
        text233: "请上传卡片背景图片",
        text234: "请选择卡片背景颜色",
        text235: "设置成功",
        text236: "图片格式只能为png",
        text237: "图片不能大于2M",
        text238: "上传图片尺寸不符合,只能是350*100"
    },
    deviceLog: {
        text1: "设备日志",
        text2: "通讯日志",
        text3: "上下线日志",
        text4: "绑定/解绑日志",
        text5: "设备ID",
        text6: "至",
        text7: "开始日期",
        text8: "结束日期",
        text9: "请选择",
        text10: "序号",
        text11: "时间",
        text12: "投递类型",
        text13: "日志详情"
    },
    debug: {
        text1: "设备调试",
        text2: "如何进行调试",
        text3: "没有产品拟物图",
        text4: "切换产品",
        text5: "品类：",
        text6: "暂无可调试产品",
        text7: "真机设备列表",
        text8: "请通过APP打开调试模式后进行添加",
        text9: "设备ID",
        text10: "绑定用户",
        text11: "状态",
        text12: "离线",
        text13: "在线",
        text14: "设备调试",
        text15: "操作",
        text16: "删除",
        text17: "暂无可调试设备",
        text18: "虚拟设备列表",
        text19: "新增虚拟设备",
        text20: "应用调试",
        text21: "1、如未下载APP，可扫描 下方二维码直接下载APP",
        text22: "2、已下载APP，请直接扫描下方二维码绑定设备",
        text23: "是否删除当前虚拟设备？",
        text24: "提示",
        text25: "确认",
        text26: "取消",
        text27: "删除成功",
        text28: "是否删除当前真机设备？",
        text29: "虚拟设备ID：",
        text30: "功能调试",
        text31: "功能名称",
        text32: "功能名称",
        text33: "参数",
        text34: "请选择",
        text35: "内容",
        text36: "MQTT连接失败！",
        text37: "上报失败！",
        text38: "上报成功！",
        text39: "请填写",
        text40: "上报",
        text41: "切换设备",
        text42: "设备状态",
        text43: "获取所有属性",
        text44: "属性名称",
        text45: "属性值",
        text46: "获取",
        text47: "设置",
        text48: "内容",
        text49: "设备未上报数据！",
        text50: "获取成功！",
        text51: "下发成功",
        text52: "状态码：",
    },
    empower: {
        text1: "授权服务",
        text2: "授权证书概览",
        text3: "申请证书",
        text4: "总授权申请数(个)",
        text5: "产品授权申请数(个)",
        text6: "服务授权申请数(个)",
        text7: "已激活总数(个)",
        text8: "授权清单",
        text9: "需要帮助？",
        text10: "产品名称/服务名称/PID/SID",
        text11: "至",
        text12: "开始日期",
        text13: "结束日期",
        text14: "授权对象",
        text15: "授权目的",
        text16: "序号",
        text17: "授权对象",
        text18: "授权产品/服务",
        text19: "授权目的",
        text20: "激活方式",
        text21: "可授权总数",
        text22: "通过审核后允许授权数量",
        text23: "剩余激活数",
        text24: "指剩余可使用的授权数",
        text25: "试用数",
        text26: "申请时间",
        text27: "申请状态",
        text28: "操作",
        text29: "详情",
        text30: "设备凭证",
        text31: "停用",
        text32: "启用",
        text33: "重新认证",
        text34: "复制成功",
        text35: "授权凭证",
        text36: "确定",
        text37: "是否停用?",
        text38: "是否启用?",
        text39: "提示",
        text40: "取消",
        text41: "停用成功",
        text42: "启用成功",
        text43: "是否关闭自动计数?",
        text44: "是否开启自动计数?",
        text45: "关闭",
        text46: "开启",
        text47: "关闭成功",
        text48: "开启成功",
        text50: "新增授权",
        text51: "请输入申请数量",
        text52: "取 消",
        text53: "确 认",
        text54: "只能输入正整数",
        text55: "申请提交成功",
        text56: "申请授权凭证",
        text57: "请选择授权对象",
        text58: "非平台新建产品请选择服务获取",
        text59: "授权产品",
        text60: "请输入产品名称搜索",
        text61: "请选择服务（多选）",
        text62: "授权目的",
        text63: "导入设备唯一标识",
        text64: "模版下载",
        text65: "点击或将文件拖拽到这里上传",
        text66: "支持文件格式：",
        text67: "申请数量(个)",
        text68: "指申请授权的总数（审核和未审核都计算）",
        text69: "请输入",
        text70: "是否支持试用？（天）",
        text71: "请输入试用天数",
        text72: "申 请",
        text73: "请选择产品",
        text74: "请选择服务",
        text75: "请选择授权目的",
        text76: "申请数量最多为50000",
        text77: "请输入license申请数量",
        text78: "调试目的下，申请数量最多为300",
        text79: "请上传设备唯一标识",
        text80: "唯一标识中有空标识，请仔细检查",
        text81: "申请发送成功",
        text82: "excel表中没有数据",
        text83: "新增激活",
        text84: "请输入设备唯一标识",
        text85: "上传文件",
        text86: "确 定",
        text87: "请填写唯一标识或者上传excel文件",
        text88: "新增成功",

        text89: "授权服务详情",
        text90: "授权编号",
        text91: "申请日期",
        text92: "支持试用",
        text93: "天",
        text94: "申请总数",
        text95: "已激活数量",
        text96: "剩余数量",
        text97: "待审核",
        text98: "已通过",
        text99: "拒绝",

        text100: "激活列表",
        text101: "待审核中",
        text102: "审核未通过",
        text103: "License搜索",
        text104: "设备唯一标识搜索",
        text105: "全部",
        text106: "导出",
        text107: "设备唯一标识",
        text108: "激活状态",
        text109: "激活日期",
        text110: "筛选条件：",
        text111: "请选择",
        text112: "导出数量：",
        text113: "请输入导出数量",
        text114: "是否优先导出未导出过的License",
        text115: "请选择筛选条件",
        text116: "是否确认导出当前激活列表？",
        text117: "确认",
        text118: "导出License成功",
        text119: "业务单号"
    },
    dashboard: {
        text1: "厂测数据",
        text2: "没有产品拟物图",
        text3: "切换产品",
        text4: "品类：",
        text5: "每天00:00更新数据",
        text6: "请搜索工单号",
        text7: "请选择",
        text8: "工单号",
        text9: "地址",
        text10: "编号",
        text11: "总测试量",
        text12: "总良品量",
        text13: "总不良量",
        text14: "总不良率",
        text15: "测试时间",
        text16: "操作",
        text17: "详情",
        text18: "修改地址",
        text19: "请输入地址",
        text20: "取 消",
        text21: "修 改",
        text22: "修改成功",
        text23: "异常功能项分布情况",
        text24: "导出",
        text25: "异常占比",
        text26: "上线统计",
        text27: "设备上线统计",
        text28: "仅统计24小时内的数据，无数据的设备则不显示。",
        text29: "产品品类",
        text30: "全部品类",
        text31: "请选择日期",
        text32: "请输入设备ID",
        text33: "设备ID",
        text34: "总次数",
        text35: "评估",
        text36: "超阈值显“异常”，未超则正常且不展示于表单。默认阈值为50次/天",
        text37: "异常",
        text38: "正常",
        text39: "开始时间",
        text40: "结束时间",
        text41: "请搜索地址",
        text42: "用户",
        text43: "用户信息",
        text44: "修改备注"
    },
    notice: {
        text1: "标记已读",
        text2: "类型",
        text3: "公告人",
        text4: "标题",
        text5: "日期",
        text6: "状态",
        text7: "已读",
        text8: "未读",
        text9: "全部公告",
        text10: "更新",
        text11: "消息",
        text12: "请选择需要标记的消息"
    },
    userInfo: {
        text1: "个人信息",
        text2: "上传头像",
        text3: "可以拖动图片到左边头像区域完成上传",
        text4: "昵称",
        text5: "请输入昵称",
        text6: "修改昵称",
        text7: "确定",
        text8: "取消",
        text9: "用户ID",
        text10: "国家/地区",
        text11: "地址",
        text12: "请输入地址",
        text13: "修改地址",
        text14: "密码",
        text15: "修改密码",
        text16: "手机号",
        text17: "未绑定",
        text18: "修改手机",
        text19: "绑定手机",
        text20: "邮箱",
        text21: "修改邮箱",
        text22: "绑定邮箱",
        text23: "请填写昵称！",
        text24: "请填写地址！",
        text25: "请上传头像！",
        text26: "修改成功",
        text27: "上传头像尺寸不符合,只能是200*200！",
        text28: "图片仅支持jpg/jpeg/png/gif/bmp格式！",
        text29: "绑定手机号",
        text30: "绑定邮箱",
        text31: "请选择国家/地区",
        text32: "请输入手机号",
        text33: "请输入邮箱",
        text34: "验证码",
        text35: "取 消",
        text36: "确 认",
        text37: "获取验证码",
        text38: "请选择国家/地区",
        text39: "请输入验证码",
        text40: "手机号格式不正确",
        text41: "邮箱格式不正确",
        text42: "s后重新获取",
        text43: "重新获取验证码",
        text44: "请先获取验证码",
        text45: "绑定成功",
        text46: "更换手机号",
        text47: "更换邮箱",
        text48: "为确认是本人操作，请输入密码校验身份：",
        text49: "请输入新手机号",
        text50: "请输入新邮箱",
        text51: "下一步",
        text52: "请输入密码",
        text53: "密码不正确",
        text54: "请输入手机号码",
        text55: "密码修改",
        text56: "验证账号：",
        text57: "新密码（不能超过20个字符）",
        text58: "再次输入新密码（不能超过20个字符）",
        text59: "请再次输入密码",
        text60: "两次输入密码不一致！",
        text61: "请输入旧密码",
        text62: "请输入新密码",
        text63: "必须包含大小写字母、数字、特殊字符、长度不小于8",
        text64: "修改成功,请重新登录"
    },
    enterpriseInfo: {
        text1: "企业管理",
        text2: "企业认证",
        text3: "企业名称",
        text4: "企业简称",
        text5: "英文简称",
        text6: "企业法人",
        text7: "联系人",
        text8: "联系方式",
        text9: "申请状态",
        text10: "操作",
        text11: "重新认证",
        text12: "拥有者",
        text13: "开发人员",
        text14: "产品人员",
        text15: "申请认证",
        text16: "企业全称：",
        text17: "请输入企业全称",
        text18: "企业简称：",
        text19: "请输入企业简称",
        text20: "英文简称：",
        text21: "请输入英文简称",
        text22: "企业法人：",
        text23: "请输入企业法人",
        text24: "法人手持身份证：",
        text25: "文件大小不能超过10M，格式为png、jpeg、jpg、gif、bmp",
        text26: "企业地址：",
        text27: "请输入企业地址",
        text28: "营业执照：",
        text29: "企业简介：",
        text30: "联系人：",
        text31: "请输入联系人",
        text32: "联系电话：",
        text33: "请输入联系电话",
        text34: "修改申请",
        text35: "申请注册",
        text36: "填写企业信息",
        text37: "填写联系人(请保持电话通畅,否则可能延长审核时间)",
        text38: "提交申请",
        text39: "输入不能超过20个字符",
        text40: "只能输入10个字符以内的英文字母",
        text41: "输入不能超过10个字符",
        text42: "请输入联系人电话",
        text43: "请上传法人手持身份证照片",
        text44: "请上传营业执照",
        text45: "修改成功！",
        text46: "注册成功！",
        text47: "图片仅支持jpg/jpeg/png/gif/bmp格式！",
        text48: "上传图片大小不能超过 10MB！"
    },
    member: {
        text1: "成员管理",
        text2: "备注名",
        text3: "添加成员",
        text4: "成员ID",
        text5: "备注名",
        text6: "成员权限",
        text7: "可用产品",
        text8: "所有产品",
        text9: "操作",
        text10: "修改",
        text11: "删除",
        text12: "移交",
        text13: "是否将该成员移除出企业？",
        text14: "提示",
        text15: "确定",
        text16: "取消",
        text17: "删除成功",
        text18: "是否将企业移交给该用户？(移交后本账号会退出企业)",
        text19: "移交成功",
        text20: "添加成员",
        text21: "修改成员",
        text22: "国家/地区：",
        text23: "请选择国家/地区",
        text24: "用户ID：",
        text25: "输入手机号点击搜索用户",
        text26: "备注名：",
        text27: "输入备注名",
        text28: "角色：",
        text29: "可用产品：",
        text30: "全部产品",
        text31: "指定产品",
        text32: "选择产品",
        text33: "取 消",
        text34: "确 认",
        text35: "请输入手机号/邮箱搜索",
        text36: "请选择角色",
        text37: "请选择产品",
        text38: "添加成功",
        text39: "请求错误",
        text40: "修改成功",
        text41: "请选择",
        text42: "产品名称/PID",
        text43: "全部通讯方式",
        text44: "全部状态",
        text45: "产品图",
        text46: "产品名称",
        text47: "产品类型",
        text48: "通讯方式",
        text49: "创建时间",
        text50: "备注",
        text51: "开发状态"
    },
    ota: {
        text1: "OTA升级",
        text2: "内部产品",
        text3: "外部产品",
        text4: "请输产品名称进行搜索切换",
        text5: "暂无数据",
        text6: "发布时间区间",
        text7: "请选择固件/APP标识",
        text8: "请输入版本号",
        text9: "新增OTA",
        text10: "版本号",
        text11: "触发方式",
        text12: "固件类型",
        text13: "更新说明",
        text14: "发布时间",
        text15: "状态",
        text16: "未发布",
        text17: "指定发布",
        text18: "灰度发布",
        text19: "全量发布",
        text20: "计划发布时间",
        text21: "操作",
        text22: "下载固件",
        text23: "发布",
        text24: "详情",
        text25: "编辑",
        text26: "下架",
        text27: "删除",
        text28: "设备主动(自检升级)",
        text29: "服务端触发升级",
        text30: "移动端(App触发升级)",
        text31: "检测升级",
        text32: "下架后，终端将无法再升级此版本，重新发布后可下载，请慎重确认是否下架？",
        text33: "温馨提示",
        text34: "已知晓",
        text35: "取消",
        text36: "下架成功",
        text37: "删除后不能撤销，是否继续删除？",
        text38: "温馨提示",
        text39: "删除",
        text40: "删除成功",
        text41: "基本设置",
        text42: "产品名称/昵称",
        text43: "产品名称：在IoT平台已创建产品的名称，您可选择产品名称。",
        text44: "产品昵称：没有在平台创建产品，但需使用OTA功能，您可输入/选择产品昵称。",
        text45: "请选择产品名称/昵称",
        text46: "固件/APP标识",
        text47: "固件标识的一致性验证，是确保升级对象是否在OTA升级范围内的关键条件之一。",
        text48: "输入择固件/APP标识",
        text49: "升级包仅支持.zip格式，大小不超过8GByte",
        text50: "注意：上传升级包时，请确保包含版本号等必要信息。详情查看",
        text51: "升级包示例",
        text52: "设备端升级设置",
        text53: "升级包",
        text54: "请参照示例进行升级包打包，否则有可能导致升级失败",
        text55: "触发方式",
        text56: "1、自检升级：在设备的设置时间点（如每天凌晨三点）自动执行检测，符合条件即刻升级。",
        text57: "2、检测升级：用户通过设备的人机交互界面（如触摸屏）触发设备检测，符合条件即刻升级。",
        text58: "3、APP触发升级：用户在小蜂连连APP操作，设备一旦在线触发设备检测，符合条件即刻升级。",
        text59: "4、服务器触发：新固件发布后，服务器推送更新指令至设备，设备一旦在线即自动执行升级。",
        text60: "请选择触发方式",
        text61: "请选择下载方式",
        text62: "请选择安装方式",
        text63: "下载方式",
        text64: "前台下载：固件下载，用户可见。",
        text65: "静默下载：固件下载，用户不可见。",
        text66: "安装方式",
        text67: "强制安装：必须安装，不支持取消下载安装。",
        text68: "非强制安装：支持用户取消下载安装。",
        text69: "安装重启",
        text70: "擦除分区",
        text71: "新增分区",
        text72: "更新说明",
        text73: "请输入更新说明",
        text74: "新增",
        text75: "修改",
        text76: "产品名称",
        text77: "产品昵称",
        text78: "请输入固件/APP标识",
        text79: "请选择是否安装重启",
        text80: "前台下载",
        text81: "静默下载",
        text82: "是",
        text83: "否",
        text84: "数据分区",
        text85: "媒体分区",
        text86: "未找到该产品名称/昵称，是否创建为新的产品昵称？",
        text87: "温馨提示",
        text88: "请输入分区名称",
        text89: "确定",
        text90: "分区名称大于0小于等于20字符",
        text91: "新增成功",
        text92: "文件大小不能超过不超过8GByte",
        text93: "请上传升级包",
        text94: "添加成功",
        text95: "修改成功",
        text96: "芯片固件",
        text97: "鸿蒙",
        text98: "WIFI模组固件",
        text99: "强制安装",
        text100: "非强制安装",
        text101: "开始下载",
        text102: "下载完成",
        text103: "开始升级",
        text104: "升级完成",
        text105: "修改OTA",
        text106: "重置",
        text107: "新增外部产品",
        text108: "请输入产品名称（长度为1~20）"
    },
    releaseMsg: {
        text1: "发布",
        text2: "固件标识：",
        text3: "版本号",
        text4: "产品ID",
        text5: "创建时间",
        text6: "固件类型",
        text7: "下载地址",
        text8: "复制",
        text9: "编辑",
        text10: "取消",
        text11: "确认",
        text12: "请输入内容",
        text13: "设备端升级设置",
        text14: "安装方式",
        text15: "触发方式",
        text16: "安装重启",
        text17: "更新说明",
        text18: "总设备数（台）",
        text19: "已升级设备数（台）",
        text20: "指定发布",
        text21: "灰度发布",
        text22: "全量发布",
        text23: "下架后，终端将无法再升级此版本，重新发布后可下载，请慎重确认是否下架？",
        text24: "温馨提示",
        text25: "已知晓",
        text26: "取消",
        text27: "下架成功",
        text28: "请选择发布类型",
        text29: "灰度发布数量不能为0",
        text30: "请先填写需要发布的设备唯一标识",
        text31: "请先选择计划发布时间",
        text32: "定时发布的时间必须设置在当前时间后的至少10分钟",
        text33: "发布成功",
        text34: "修改成功",

        text39: "指定发布设置",
        text40: "请输入设备唯一标识。",
        text41: "格式说明：",
        text42: "多个设备用“,”（逗号）隔开，如：AA:62:94:09:11:10,AA:62:94:09:11:12",
        text43: "连续区间用“起始值~终止值”，如：AA:62:94:09:11:14~AA:62:94:09:11:20",
        text44: "本地上传",
        text45: "上传前",
        text46: "下载模板",
        text47: "，根据模版要求规范格式",
        text48: "灰度发布设置",
        text49: "发布时间",
        text50: "立即发布",
        text51: "定时发布",
        text52: "选择发布时间",
        text53: "发布",
        text54: "下架",
        text55: "编辑",
        text56: "保存",
        text57: "全量发布"
    },
    firmwareMsg: {
        text1: "详情",
        text2: "固件标识：",
        text3: "版本号",
        text4: "产品ID",
        text5: "创建时间",
        text6: "固件类型",
        text7: "下载地址",
        text8: "复制",
        text9: "编辑",
        text10: "取消",
        text11: "确认",
        text12: "请输入内容",
        text13: "设备端升级设置",
        text14: "安装方式",
        text15: "触发方式",
        text16: "安装重启",
        text17: "是",
        text18: "否",
        text19: "发布设置",
        text20: "发布方式",
        text21: "发布时间",
        text22: "发布范围",
        text23: "升级数据",
        text24: "请搜索设备唯一标识",
        text25: "设备总数（台）",
        text26: "升级成功（台）",
        text27: "升级失败（台）",
        text28: "正在升级（台）",
        text29: "占比:",
        text30: "未升级（台）",
        text31: "设备唯一标识",
        text32: "设备状态",
        text33: "离线",
        text34: "在线",
        text35: "当前版本",
        text36: "升级状态",
        text37: "升级时间",
        text38: "修改成功"
    },
    role: {
        text1: "角色管理",
        text2: "新增角色",
        text3: "角色名称",
        text4: "角色描述",
        text5: "操作",
        text6: "查看",
        text7: "编辑",
        text8: "授权",
        text9: "删除",
        text10: "新增下级角色",
        text11: "删除该角色，角色下的用户也会被删除确认要删除吗？",
        text12: "确认",
        text13: "取消",
        text14: "删除成功",
        text15: "请求错误",
        text16: "角色授权",
        text17: "全选",
        text18: "操作成功",
        text19: "编辑角色",
        text20: "查看角色",
        text21: "上级角色：",
        text22: "角色名称：",
        text23: "请输入角色名称",
        text24: "角色描述：",
        text25: "请输入角色描述",
        text26: "已选菜单：",
        text27: "取 消",
        text28: "修 改",
        text29: "确 认",
        text30: "输入不能超过10个字符",
        text31: "修改成功",
        text32: "添加成功"
    },
    global: {
        text1: "退出成功",
        text2: "请求错误",
        text3: "登录失效",
        text4: "接口请求失败",
        text5: "接口响应异常"
    },
    router: {
        text1: "朗国IoT平台",
        text2: "APP下载",
        text3: "朗国物联网平台",
        text4: "新建产品",
        text5: "设备管理",
        text6: "OTA列表",
        text7: "编辑产品",
        text8: "设备调试",
        text9: "应用调试",
        text10: "授权服务详情",
        text11: "设备日志",
        text12: "测试",
        text13: "详情",
        text14: "企业认证",
        text15: "公告中心",
        text16: "注销账号",
        text17: "发布",
        text18: "配网指引"
    },
    transaction: {
        text1: "很抱歉，你访问的页面找不到了",
        text2: "返回首页"
    }
}
