import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/azsgw";
} else {
    url = "/lgiot/empower/azsgw";
}

/**
 * 授权列表查询
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 * }
 * @returns {*}
 */
const queryAuthorizationBusiness = data => {
    return service({
        url: url + '/authorizationBusiness/queryPager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增授权申请
 * @param {
 *      *authorizationBusinessCode 授权业务Code
 *      *authorizationSwitch 授权开关 0:关闭 1:开启
 *      *autoAuthorizationSwitch 自动授权开关 0:关闭 1:开启
 *      licenseAmount 申请license数量
 *      *objectInfo 授权对象信息,JSON格式[{"authorizationBusinessCode":1000545067,"objectId":"1L9zO9O-15IbtF","objectName":"无线传屏基础版"},{"authorizationBusinessCode":1000558968,"objectId":"1L9zO9O-15If5S","objectName":"无线传屏全功能版"}]
 *      *objective 授权目的 1:调试 2:量产
 *      *totalAuthorization 授权申请总量
 *      *uniqueKeys 设备唯一标识，多个用逗号隔开，直接输入和文件导入只能选一种方式
 *      *type 授权类型 1:产品 2:服务
 *      businessOrderNo 业务单号
 * }
 * @returns {*}
 */
const addAuthorization = data => {
    return service({
        url: url + '/authorization/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增导入授权成员
 * @param {
 *     *authorizationCode 授权申请Code
 *     *file 设备唯一标识excel表
 *     *uniqueKey 直接输入的设备唯一标识，多个用逗号隔开
 * }
 * @returns {*}
 */
const importMemberAuthorization = data => {
    return service({
        url: url + '/authorization/importMember',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 授权列表查询
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     startCreateTime 查询创建时间段-开始时间 如:2022-07-23 00:00:00
 *     endCreateTime 查询创建时间段-结束时间 如:2022-07-26 00:00:00
 *     objective 授权目的 1:调试 2:量产
 *     type 授权类型 1:产品 2:服务
 *     key 授权对象(产品名/服务名/PID/SID)
 *     queryType 查询类型 1:产品名称/服务名称;2:PID/SID;3:授权编号;4:业务单号;5:唯一标识;
 * }
 * @returns {*}
 */
const queryAuthorization = data => {
    return service({
        url: url + '/authorization/queryPager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 下载设备凭证
 * @returns {*}
 */
const exportDeviceCredentials = data => {
    return service({
        url: url + '/authorization/deviceCredentials',
        method: 'get',
        isToken: true,
        responseType: 'blob',
        params: data,
        contentType: 'application/x-www-form-urlencoded'
    })
}

/**
 * 导出授权文件
 * @returns {*}
 */
const exportAuthorizationFile = data => {
    return service({
        url: url + '/authorization/exportAuthorizationFile',
        method: 'get',
        isToken: true,
        responseType: 'blob',
        params: data,
        contentType: 'application/x-www-form-urlencoded'
    })
}


/**
 * 修改授权申请
 * @param {
 *     *authorizationCode  授权申请Code
 *     *authorizationSwitch 授权开关 0:关闭 1:开启
 * }
 * @returns {*}
 */
const updateAuth = data => {
    return service({
        url: url + '/authorization/update',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 授权列表查询
 * @returns {*}
 */
const statisticsAuth = () => {
    return service({
        url: url + '/authorization/statistics',
        method: 'get',
        isToken: true,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据授权申请Code查询授权申请详情
 * @param {
 *     *authorizationCode 授权申请Code
 * }
 * @returns {*}
 */
const queryAuthDetails = data => {
    return service({
        url: url + '/authorization/details',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询设备唯一标识列表
 * @param {
 *     *authorizationCode 授权申请Code
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     endCreateTime 查询创建时间段-结束时间 如:2022-07-26 00:00:00
 *     startCreateTime 查询创建时间段-开始时间 如:2022-07-23 00:00:00
 *     status 状态 - 1:待激活; 2:已激活
 *     *uniqueKey uniqueKey
 * }
 * @returns {*}
 */
const queryUniqueKey = data => {
    return service({
        url: url + '/authorizationMember/queryuniquekey',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询License列表
 * @param {
 *     *authorizationCode 授权申请Code
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     endCreateTime 查询创建时间段-结束时间 如:2022-07-26 00:00:00
 *     startCreateTime 查询创建时间段-开始时间 如:2022-07-23 00:00:00
 *     status 状态 - 1:待激活; 2:已激活
 *     *license license
 * }
 * @returns {*}
 */
const queryLicense = data => {
    return service({
        url: url + '/authorizationMember/querylicense',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询列表
 * @param {
 *     *authorizationCode 授权申请Code
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     endCreateTime 查询创建时间段-结束时间 如:2022-07-26 00:00:00
 *     startCreateTime 查询创建时间段-开始时间 如:2022-07-23 00:00:00
 *     key 搜索内容
 *     status 状态 - 1:待激活; 2:已激活
 * }
 * @returns {*}
 */
const queryMember = data => {
    return service({
        url: url + '/authorizationMember/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取授权凭证
 * @param {
 *     *authorizationCode 授权申请Code
 * }
 * @returns {*}
 */
const getAuthProductKeys = data => {
    return service({
        url: url + '/authorization/getproductkeys',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 导出license列表
 * @param {
 *     *amount 导出数量
 *     *authorizationCode 授权申请code
 *     noviewed 优先导出未查看过 - Y:是; N:否
 *     *status 状态 - 1:待激活; 2:已激活
 * }
 * @returns {*}
 */
const exportLicense = data => {
    return service({
        url: url + '/exportlicense',
        method: 'get',
        isToken: true,
        responseType: 'blob',
        params: data,
        contentType: 'application/x-www-form-urlencoded'
    })
}

/**
 * 生成license
 * @param {
 *     *amount 申请数量
 *     *authorizationCode 授权申请编码
 * }
 * @returns {*}
 */
const generateLicense = data => {
    return service({
        url: url + '/authorization/generatelicense',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增授权数量申请
 * @param {
 *     *amount 新增授权申请数量
 *     *authorizationCode 授权申请编号
 * }
 * @returns {*}
 */
const addIncreaseAuthorization = data => {
    return service({
        url: url + '/increaseauthorization/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 授权申请编号获取应用
 * @param {
 *     *authorizationCode 授权申请Code
 * }
 * @returns {*}
 */
const getByAuthCode = data => {
    return service({
        url: url + '/application/getbyauthorizationcode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 导出唯一标识
 * @param data
 * @returns {*}
 */
const exportuniquekey = data => {
    return service({
        url: url + '/exportuniquekey',
        method: 'get',
        isToken: true,
        responseType: 'blob',
        params: data,
        contentType: 'application/x-www-form-urlencoded'
    })
}

/**
 * 重新认证(不支持传入excel文件)
 * @param {
 *     allowProbation 授权类型为产品时不需要，对于服务则表示是否允许试用 - Y:是; N:否
 *     *authorizationSwitch 授权开关 0:关闭 1:开启
 *     *autoAuthorizationSwitch 自动授权开关 0:关闭 1:开启
 *     *code 授权申请code
 *     licenseAmount 申请license数量
 *     *objectInfo 授权对象信息,产品/服务
 *     objective 授权目的 1:调试 2:量产
 *     probationPeriod 授权类型为产品时不需要，对于服务则表示试用天数
 *     totalAuthorization 授权申请总量
 *     *type 授权类型 1:产品 2:服务
 *     uniqueKeys 设备唯一标识，多个用逗号隔开，直接输入和文件导入只能选一种方式
 *     validityPeriod 授权有效天数
 * }
 * @returns {*}
 */
const reAddAuthorization = data => {
    return service({
        url: url + '/authorization/reAdd',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    addAuthorization,
    queryAuthorizationBusiness,
    importMemberAuthorization,
    queryAuthorization,
    exportDeviceCredentials,
    exportAuthorizationFile,
    updateAuth,
    statisticsAuth,
    queryAuthDetails,
    queryUniqueKey,
    queryLicense,
    getAuthProductKeys,
    exportLicense,
    generateLicense,
    addIncreaseAuthorization,
    getByAuthCode,
    exportuniquekey,
    reAddAuthorization,
    queryMember
}
